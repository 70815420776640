.corporateLaw1 {
  margin-bottom: 0px;
}
.corporateLaw {
  margin: 0;
  padding-left: var(--padding-48xl);
}
.mergersAndAcquisitions {
  letter-spacing: 0.03em;
  line-height: 130.26%;
}
.assistingWithTheBuyingSel {
  font-size: var(--font-size-14xl);
}
.mergersAndAcquisitionsAssi1 {
  font-weight: 600;
}
.mergersAndAcquisitionsAssi {
  margin: 10px 10px 10px 30px;
  max-width: 1000px;
}
.corporateGovernance {
  letter-spacing: 0.01em;
}
.jointVenturesAnd {
  font-size: var(--font-size-17xl);
}
.corporateLawMergersContainer {
  flex: 1;
  position: relative;
  display: inline-block;
  max-width: 100%;
}
.corporateLawMergersAndAcqParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 1.5rem;
  max-width: 100%;
}
.fiscalAndTax {
  margin: 0;
  font-weight: 800;
  white-space: pre-wrap;
}
.taxPlanningAndComplianceP2 {
  line-height: 130.26%;
}
.taxPlanningAndComplianceP {
  margin: 0;
  font-size: var(--font-size-14xl);
}
.fiscalAndTaxContainer {
  align-self: stretch;
  position: relative;
  display: inline-block;
}
.frameChild {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.commercialLaw {
  margin: 0;
  font-size: var(--font-size-31xl);
  font-weight: 800;
}
.blankLine {
  margin: 0;
  font-size: var(--font-size-31xl);
}
.complianceAuditsConducting {
  margin: 10px 10px 10px 30px;
  font-weight: 600;
}
.commercialLawContainer {
  position: relative;
  line-height: 130.26%;
  display: inline-block;
}
.arbitrationRepresentingContainer {
  position: relative;
  line-height: 130.26%;
  color: var(--color-black);
  display: inline-block;
  max-width: 100%;
}
.commercialLawContractDrafParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4.422rem;
  max-width: 100%;
}
.maskGroupIcon {
  flex: 1;
  position: relative;
  max-width: 23rem;
  overflow: hidden;
  object-fit: cover;
  min-width: 21.667rem;
  align-self:stretch;
}
.frameGroup {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start ;
  justify-content: space-between;
  row-gap: 20px;
  max-width: 100%;
  font-size: var(--font-size-17xl);
}
.familyAndPersonal {
  font-weight: 800;
}
.intellectualPropertyPleaseContainer {
  position: relative;
  line-height: 130.26%;
  display: inline-block;
  font-size: var(--font-size-17xl);
}
.frameParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3.888rem;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-31xl);
  color: var(--color2);
  font-family: var(--body1-regular);
}
.servicesInner {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  max-width: 100%;
  padding: 0px 2rem;
}
.services {
  width: 100%;
  position: relative;
  background-color: var(--color);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-81xl);
  line-height: normal;
  letter-spacing: normal;
  padding: auto ;
}

@media screen and (max-width: 500px) {
  .maskGroupIcon{
    display: none;
  }
  .services{
    gap:var( --gap-52xl) ;
  }
} 
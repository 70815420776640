.exceeding {
  margin: 0;
  line-height: false;
}
.exceedingExpectationsElevatContainer {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  text-transform: capitalize;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
}
.triumph {
  margin: 0;
}
.triumphCoContainer {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-77xl);
  letter-spacing: 0.03em;
  line-height: 6.167rem;
  text-transform: capitalize;
  font-weight: 400;
  font-family: var(--display2);
  color: var(--color3);
}
.lawFirm {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-41xl);
  line-height: 6.222rem;
  text-transform: capitalize;
  font-weight: 400;
  font-family: inherit;
}
.hero1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 27.778rem;
  max-width: 100%;
}
.maskGroupIcon {
  height: 33.333rem;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
}
.hero {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-131xl);
  box-sizing: border-box;
  gap: var(--gap-26xl);
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-26xl);
  color: var(--color-gray-200);
  font-family: var(--body1-regular);
}

@media screen and (max-width: 1890px) {
  .hero {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1425px) {
  .hero {
    padding-left: var(--padding-56xl);
    padding-right: var(--padding-56xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 950px) {
  .exceedingExpectationsElevatContainer {
    font-size: var(--font-size-17xl);
  }

  .triumphCoContainer {
    font-size: var(--font-size-29xl);
    line-height: 3.722rem;
  }

  .lawFirm {
    font-size: var(--font-size-29xl);
    line-height: 5rem;
  }

  .hero1 {
    min-width: 100%;
  }

  .maskGroupIcon {
    min-width: 100%;
  }

  .hero {
    gap: var(--gap-3xl);
    padding-left: var(--padding-18xl);
    padding-right: var(--padding-18xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .exceedingExpectationsElevatContainer {
    font-size: var(--font-size-8xl);
  }

  .triumphCoContainer {
    font-size: var(--font-size-10xl);
    line-height: 2.444rem;
  }

  .lawFirm {
    font-size: var(--font-size-17xl);
    line-height: 3.722rem;
  }
}

.news1 {
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.theLatestNews {
  margin: 0;
}
.theLatestNewsContainer {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-45xl);
  line-height: 4.778rem;
  font-weight: 600;
  font-family: inherit;
  color: var(--color-gray-200);
  display: inline-block;
}
.presenceItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
}
.triumphInfo {
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0rem var(--padding-91xl);
  box-sizing: border-box;
  gap: var(--gap-81xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-17xl);
  color: var(--color2);
}
.news {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem var(--padding-xl);
  box-sizing: border-box;
  max-height: 50rem;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--color3);
  font-family: var(--body1-regular);
}

@media screen and (max-width: 1425px) {
  .triumphInfo {
    padding-left: var(--padding-36xl);
    padding-right: var(--padding-36xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 950px) {
  .theLatestNewsContainer {
    font-size: var(--font-size-32xl);
    line-height: 3.833rem;
  }

  .triumphInfo {
    gap: var(--gap-31xl);
    padding-left: var(--padding-8xl);
    padding-right: var(--padding-8xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .news1 {
    font-size: var(--font-size-lgi);
  }

  .theLatestNewsContainer {
    font-size: var(--font-size-19xl);
    line-height: 2.889rem;
  }

  .triumphInfo {
    gap: var(--gap-6xl);
  }
}

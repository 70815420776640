@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxanium:wght@600&display=swap");

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

html {
  font-size: small;
}
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --body1-regular: "DM Sans";
  --display2: "DM Serif Display";

  /* font sizes */
  --body1-regular-size: 1rem;
  --font-size-5xl: 1rem;
  --font-size-21xl: 1.666rem;
  --font-size-13xl: 1.333rem;
  --h1-size: 3rem;
  --font-size-24xl: 1.791rem;
  --font-size-39xl: 2.416rem;
  --font-size-lgi: 0.792rem;
  --font-size-25xl: 1.833rem;
  --font-size-41xl: 2.5rem;
  --font-size-7xl: 1.083rem;
  --font-size-61xl: 3.333rem;
  --font-size-31xl: 2.083rem;
  --font-size-11xl: 1.25rem;
  --font-size-77xl: 4rem;
  --font-size-10xl: 1.208rem;
  --font-size-29xl: 2rem;
  --font-size-15xl: 1.416rem;
  --font-size-45xl: 2.667rem;
  --font-size-17xl: 1.5rem;
  --font-size-19xl: 1.583rem;
  --font-size-32xl: 2.125rem;
  --font-size-26xl: 1.875rem;
  --font-size-8xl: 1.125rem;
  --font-size-xl: 0.833rem;
  --display2-size: 3.917rem;
  --font-size-6xl: 1.042rem;
  --font-size-14xl: 1.375rem;
  --font-size-3xl: 0.916rem;
  

  /* Colors */
  --color: #e8e9e1;
  --color-gray-100: #85898a;
  --color-gray-200: #002340;
  --color-gray-300: #1a1a1a;
  --color1: #fff;
  --color-royalblue: #007aff;
  --color2: #3a3a38;
  --color3: #1e2e45;
  --color4: #022a3a;
  --color-darkslategray-100: rgba(58, 58, 56, 0.4);
  --color-darkgray: #9d9fa2;
  --color-snow: #fffcfc;
  --color-black: #000;

  /* Gaps */
  --gap-81xl: 5.555rem;
  --gap-6xl: 1.388rem;
  --gap-31xl: 2.777rem;
  --gap-xl: 1.111rem;
  --gap-mini: 0.833rem;
  --gap-52xl: 3.944rem;
  --gap-lg: 1rem;
  --gap-16xl: 1.944rem;
  --gap-0: 0rem;
  --gap-7xl: 1.444rem;
  --gap-xs: 0.666rem;
  --gap-3xs: 0.555rem;
  --gap-21xl: 2.222rem;
  --gap-11xl: 1.666rem;
  --gap-26xl: 2.5rem;
  --gap-3xl: 1.222rem;
  --gap-61xl: 4.444rem;
  --gap-11xs: 0.111rem;
  --gap-9xl: 1.555rem;

  /* Paddings */
  --padding-31xl: 2.777rem;
  --padding-13xl: 1.777rem;
  --padding-6xl: 1.388rem;
  --padding-xl: 1.111rem;
  --padding-144xl: 9.055rem;
  --padding-21xl: 2.222rem;
  --padding-62xl: 4.5rem;
  --padding-131xl: 8.333rem;
  --padding-18xl: 2.055rem;
  --padding-56xl: 4.166rem;
  --padding-104xl: 6.833rem;
  --padding-42xl: 3.388rem;
  --padding-54xl-5: 4.083rem;
  --padding-11xl: 1.666rem;
  --padding-1749xl: 98.222rem;
  --padding-423xl: 24.555rem;
  --padding-865xl: 49.111rem;
  --padding-3xs: 0.555rem;
  --padding-35xl: 3rem;
  --padding-197xl: 12rem;
  --padding-89xl: 6rem;
  --padding-2xl: 1.166rem;
  --padding-147xl: 9.222rem;
  --padding-181xl: 11.111rem;
  --padding-81xl: 5.555rem;
  --padding-102xl: 6.722rem;
  --padding-32xl: 2.833rem;
  --padding-60xl: 4.388rem;
  --padding-91xl: 6.111rem;
  --padding-8xl: 1.5rem;
  --padding-36xl: 3.055rem;
  --padding-22xl: 2.277rem;
  --padding-64xl: 4.611rem;
  --padding-151xl: 9.444rem;
  --padding-155xl: 9.666rem;
  --padding-331xl: 19.444rem;
  --padding-48xl: 3.722rem;
  --padding-25xl: 2.444rem;
  --padding-10xl: 1.611rem;
  --padding-lg: 1rem;
  --padding-7xl: 1.444rem;

  /* Border radiuses */
  --br-mini: 15px;
}
@media screen and (max-width: 500px) {
  html {
    font-size: 8px;
  }
}
@media screen and (max-width: 1000px) {
  html {
    font-size: 10px;
  }
}
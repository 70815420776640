.image109Icon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.attorneyContainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem var(--padding-131xl);
}
.lookingForA {
  margin: 0;
  width: 50.778rem;
  position: relative;
  font-size: inherit;
  text-transform: capitalize;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.searchInput {
  border: none;
  background-color: transparent;
  align-self: stretch;
  font-family: var(--display2);
  font-size: var(--font-size-13xl);
  color: var(--color-darkslategray-100);
}
.searchContainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-197xl) 0rem;
  box-sizing: border-box;
  gap: var(--gap-xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-41xl);
  color: var(--color1);
  font-family: var(--body1-regular);
}
.allAttorneys1 {
  flex: 1;
  position: relative;
}
.allAttorneys {
  width: 13.889rem;
  background-color: var(--color-darkgray);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-35xl);
  box-sizing: border-box;
}
.attorneyInfo {
  align-self: stretch;
  background-color: var(--color-gray-200);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: var(--padding-xl) 0rem;
  box-sizing: border-box;
  gap: var(--gap-3xs);
  max-width: 100%;
}
.attorney {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--color1);
  font-family: var(--display2);
}

@media screen and (max-width: 1425px) {
  .attorneyContainer {
    padding-left: var(--padding-56xl);
    padding-right: var(--padding-56xl);
    box-sizing: border-box;
  }

  .searchContainer {
    padding-left: var(--padding-89xl);
    padding-right: var(--padding-89xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 950px) {
  .attorneyContainer {
    padding-left: var(--padding-18xl);
    padding-right: var(--padding-18xl);
    box-sizing: border-box;
  }

  .searchContainer {
    padding-left: var(--padding-35xl);
    padding-right: var(--padding-35xl);
    box-sizing: border-box;
  }
}

.arrowRight3 {
  height: 100%;
  flex: 1;
  position: relative;
  max-width: 2.222rem;
  overflow: hidden;
  max-height: 1.111rem;
}
.arbitration {
  margin: 0;
  height: 4.722rem;
  flex: 1;
  position: relative;
  font-size: inherit;
  text-transform: capitalize;
  font-weight: 400;
  font-family: inherit;
  line-height: 100%;
  display: flex;
  align-items: center;
}
.services {
  align-self: stretch;
  flex: 1;
  border-bottom: 2px solid var(--color-snow);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem var(--padding-3xs);
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color1);
  font-family: var(--body1-regular);
}

.triumphCo {
  margin: 0;
  position: relative;
  font-size: var(--font-size-24xl);
  text-transform: capitalize;
  font-weight: 400;
  font-family: inherit;
  color: var(--color-gray-200);
}
.support {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem var(--padding-131xl) var(--padding-102xl);
  box-sizing: border-box;
  gap: var(--gap-21xl);
  max-height: 50rem;
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--color-gray-100);
  font-family: var(--body1-regular);
}

@media screen and (max-width: 1425px) {
  .support {
    padding-left: var(--padding-56xl);
    padding-right: var(--padding-56xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 950px) {
  .triumphCo {
    font-size: var(--font-size-15xl);
  }

  .support {
    gap: var(--gap-xl);
    padding-left: var(--padding-18xl);
    padding-right: var(--padding-18xl);
    padding-bottom: var(--padding-60xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .triumphCo {
    font-size: var(--font-size-7xl);
  }

  .support {
    padding-bottom: var(--padding-32xl);
    box-sizing: border-box;
  }
}

.testimonials1 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 120%;
  text-transform: capitalize;
  font-weight: 400;
  font-family: inherit;
}
.maskGroupIcon {
  width: 16.667rem;
  height: 16.667rem;
  position: relative;
  object-fit: cover;
}
.testimonialHeader {
  height: 33.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 0rem var(--padding-xl);
  box-sizing: border-box;
  min-width: 27.778rem;
  max-width: 100%;
}
.imageIcon {
  align-self: stretch;
  height: 15.639rem;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.loremIpsumDolor {
  position: relative;
  display: inline-block;
  max-width: 100%;
  font-size: 1.2rem;
}
.icon {
  height: 9.556rem;
  width: 12.833rem;
  position: absolute;
  margin: 0 !important;
  top: 0rem;
  left: 0.028rem;
  z-index: 1;
}
.quoteContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: var(--padding-54xl-5) var(--padding-104xl) 0rem;
  box-sizing: border-box;
  position: relative;
  max-width: 100%;
  gap: var(--gap-0);
  row-gap: 20px;
}
.johnatanG {
  position: relative;
}
.starIcon {
  height: 1.333rem;
  width: 1.333rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.starRating {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.reviewed1 {
  font-size: var(--body1-regular-size);
  font-family: var(--body1-regular);
}
.reviewed {
  position: relative;
}
.reviewerInfo {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0rem var(--padding-104xl);
  gap: var(--gap-xl);
  font-size: var(--font-size-5xl);
  font-family: var(--display2);
}
.quoteContainerParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xl) 0rem;
  box-sizing: border-box;
  gap: var(--gap-xl);
  max-width: 100%;
}
.testimonial {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xl) 0rem;
  box-sizing: border-box;
  min-width: 40rem;
  max-width: 100%;
  text-align: left;
  font-size: var(--body1-regular-size);
}
.testimonials {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-131xl);
  box-sizing: border-box;
  gap: var(--gap-xs);
  max-width: 100%;
  text-align: center;
  font-size: var(--h1-size);
  color: var(--color2);
  font-family: var(--body1-regular);
}

@media screen and (max-width: 1890px) {
  .testimonialHeader {
    flex: 1;
  }

  .testimonials {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1425px) {
  .quoteContainer {
    padding-left: var(--padding-42xl);
    padding-right: var(--padding-42xl);
    box-sizing: border-box;
  }

  .testimonial {
    min-width: 100%;
  }

  .testimonials {
    padding-left: var(--padding-56xl);
    padding-right: var(--padding-56xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 950px) {
  .testimonials1 {
    font-size: var(--font-size-39xl);
    line-height: 3.833rem;
  }

  .testimonialHeader {
    min-width: 100%;
  }

  .quoteContainer {
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
  }

  .reviewerInfo {
    flex-wrap: wrap;
    padding-left: var(--padding-42xl);
    padding-right: var(--padding-42xl);
    box-sizing: border-box;
  }

  .testimonials {
    padding-left: var(--padding-18xl);
    padding-right: var(--padding-18xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .testimonials1 {
    font-size: var(--font-size-24xl);
    line-height: 2.889rem;
  }

  .johnatanG {
    font-size: var(--font-size-lgi);
  }

  .reviewerInfo {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}

.atTriumph {
  font-weight: 800;
}
.atTriumphCoWeUnderstan {
  margin: 0;
}
.blankLine1 {
  font-weight: 600;
}
.globalReachAnd {
  margin: 0;
  font-size: var(--font-size-26xl);
  font-weight: 800;
}
.withOfficesIn {
  margin: 0;
  font-weight: 600;
}
.atTriumphContainer {
  flex: 1;
  position: relative;
  letter-spacing: 0.03em;
  line-height: 130.26%;
  display: inline-block;
  min-width: 31.5rem;
  max-width: 100%;
}
.image128Icon {
  width: 38.389rem;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;
}
.atTriumphCoWeUnderstanParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 3.166rem;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-14xl);
  color: var(--color-black);
  font-family: var(--body1-regular);
}
.innovativeLegalAndFiscalSo {
  margin: 0;
  font-size: var(--font-size-21xl);
}
.oneStopShopApproachContainer {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.03em;
  line-height: 130.26%;
  color: var(--color2);
  text-align: left;
  display: inline-block;
  font-size: var(--font-size-14xl);
  font-family: var(--body1-regular);
}
.imageIcon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.clientCentricApproachAtContainer {
  width: 43.167rem;
  position: relative;
  letter-spacing: 0.03em;
  line-height: 130.26%;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.experiencedAndDiverseContainer {
  width: 45.5rem;
  position: relative;
  letter-spacing: 0.03em;
  line-height: 130.26%;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.clientCentricApproachAtTriParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-14xl);
  color: var(--color-black);
  font-family: var(--body1-regular);
}
.strategicExpansionAsContainer {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.03em;
  line-height: 130.26%;
  color: var(--color2);
  text-align: left;
  display: inline-block;
  font-size: var(--font-size-14xl);
  font-family: var(--body1-regular);
}
.frameParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem var(--padding-131xl);
  box-sizing: border-box;
  gap: 3.277rem;
  max-width: 100%;
}
.whyChoose {
  width: 100%;
  position: relative;
  background-color: var(--color);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-81xl);
  line-height: normal;
  letter-spacing: normal;
}

@media screen and (max-width: 1890px) {
  .image128Icon {
    flex: 1;
  }

  .atTriumphCoWeUnderstanParent {
    flex-wrap: wrap;
  }

  .clientCentricApproachAtContainer {
    flex: 1;
  }

  .experiencedAndDiverseContainer {
    flex: 1;
  }

  .clientCentricApproachAtTriParent {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1425px) {
  .clientCentricApproachAtContainer {
    flex: 1;
    min-width: 100%;
  }

  .experiencedAndDiverseContainer {
    flex: 1;
  }

  .clientCentricApproachAtTriParent {
    flex-wrap: wrap;
  }

  .frameParent {
    gap: 3.111rem;
    padding-left: var(--padding-56xl);
    padding-right: var(--padding-56xl);
    box-sizing: border-box;
  }

  .whyChoose {
    gap: var(--gap-31xl);
  }
}
@media screen and (max-width: 950px) {
  .atTriumphContainer {
    line-height: 2.611rem;
    min-width: 100%;
  }

  .atTriumphCoWeUnderstanParent {
    gap: var(--gap-9xl);
  }

  .oneStopShopApproachContainer {
    line-height: 2.611rem;
  }

  .clientCentricApproachAtContainer {
    line-height: 2.611rem;
    flex: 1;
  }

  .experiencedAndDiverseContainer {
    line-height: 2.611rem;
    flex: 1;
  }

  .clientCentricApproachAtTriParent {
    flex-wrap: wrap;
  }

  .strategicExpansionAsContainer {
    line-height: 2.611rem;
  }

  .frameParent {
    gap: var(--gap-9xl);
    padding-left: var(--padding-18xl);
    padding-right: var(--padding-18xl);
    box-sizing: border-box;
  }

  .whyChoose {
    gap: var(--gap-6xl);
  }
}
@media screen and (max-width: 450px) {
  .atTriumphContainer {
    font-size: var(--font-size-7xl);
    line-height: 1.944rem;
  }

  .oneStopShopApproachContainer {
    font-size: var(--font-size-7xl);
    line-height: 1.944rem;
  }

  .clientCentricApproachAtContainer {
    font-size: var(--font-size-7xl);
    line-height: 1.944rem;
    flex: 1;
  }

  .experiencedAndDiverseContainer {
    font-size: var(--font-size-7xl);
    line-height: 1.944rem;
    flex: 1;
  }

  .clientCentricApproachAtTriParent {
    font-size: var(--font-size-7xl);
    flex-wrap: wrap;
  }

  .strategicExpansionAsContainer {
    font-size: var(--font-size-7xl);

    line-height: 1.944rem;
  }
}

.firstName {
  margin: 0;
  height: 2.444rem;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
}
.enterFirstName {
  flex: 1;
  position: relative;
}
.firstNameInput {
  align-self: stretch;
  border: 1.5px solid var(--color4);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-lg) var(--padding-7xl) var(--padding-lg)
    var(--padding-11xl);
  text-align: left;
  font-size: var(--body1-regular-size);
  font-family: var(--body1-regular);
}
.firstNameField {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  min-width: 11.222rem;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--color-gray-300);
  font-family: var(--display2);
}

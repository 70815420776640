.aboutUs {
  position: relative;
}
.triumphCo {
  margin: 0;
}
.triumphCoContainer {
  width: 13.333rem;
  height: 5.111rem;
  position: relative;
  display: inline-block;
}
.aboutUsDescriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--body1-regular-size);
  color: var(--color);
  font-family: var(--body1-regular);
}
.akarIconsfacebookFill {
  height: 1.333rem;
  width: 1.333rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.socialMediaIcons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
.aboutUsColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.businessLaw {
  text-decoration: none;
  height: 1.278rem;
  position: relative;
  color: inherit;
  display: inline-block;
}
.educationLaw {
  height: 1.278rem;
  position: relative;
  display: inline-block;
}
.pagesColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
  font-size: var(--body1-regular-size);
  font-family: var(--body1-regular);
}
.unitedArabEmirates {
  margin: 0;
  color: var(--color-royalblue);
}
.contactUsUnitedContainer {
  height: 3.667rem;
  position: relative;
  display: inline-block;
}
.phoneNumber {
  height: 2.556rem;
  position: relative;
  display: inline-block;
}
.uAEContactInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
.office1909RegalContainer {
  height: 2.556rem;
  width: 11.278rem;
  position: relative;
  display: inline-block;
}
.messageIcon {
  width: 1.333rem;
  position: relative;
  height: 1.333rem;
  overflow: hidden;
  flex-shrink: 0;
}
.uAEContactDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
  text-align: center;
  font-size: var(--body1-regular-size);
  font-family: var(--body1-regular);
}
.office504BlockContainer {
  height: 3.833rem;
  width: 13.611rem;
  position: relative;
  display: inline-block;
}
.lebanonLocation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-mini);
  text-align: left;
}
.footerContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color1);
  font-family: var(--display2);
  gap: 2rem;
  padding: 10px;
}

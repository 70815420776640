.header1 {
  align-self: stretch;
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.header {
  align-self: stretch;
  height: 11.111rem;
  background-color: var(--color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: var(--gap-81xl);
}

@media screen and (max-width: 500px) {
  .header1{
    align-items: flex-start;
  }
}
.imageIcon {
  position: absolute;
  height: 97.43%;
  width: 100%;
  top: 6.05%;
  right: 0%;
  bottom: -3.48%;
  left: 0%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.image21 {
  height: 32.889rem;
  flex: 1;
  position: relative;
  overflow: hidden;
  min-width: 21.667rem;
  max-width: 33.333rem;
}

@media screen and (max-width: 800px) {
  .image21 {
    max-width: 100%;
    min-width: 100%;
  }
}

.footer {
  align-self: stretch;
  background-color: var(--color-gray-200);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem var(--padding-31xl) var(--padding-31xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color1);
  font-family: var(--display2);
}

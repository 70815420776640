.maskGroupIcon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.casesHaveBeen {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
}
.casesHaveBeenHandledSuccesWrapper {
  align-self: stretch;
  flex: 1;
  border-bottom: 1px solid var(--color1);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.loremIpsumDolor {
  align-self: stretch;
  position: relative;
  font-size: var(--body1-regular-size);
  display: inline-block;
}
.frameParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var( --gap-lg);
}
.newscard {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  min-width: 22.222rem;
  text-align: left;
  font-size: var(--font-size-17xl);
  color: var(--color2);
  font-family: var(--body1-regular);
}

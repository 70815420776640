.aboutUs {
  margin: 0;
  align-self: stretch;
  flex: 1;
  max-width: 38rem;
  position: relative;
  font-size: inherit;
  line-height: 6.667rem;
  font-weight: 400;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: solid;
}
.frameChild {
  height: 11.111rem;
  width: 0rem;
  position: relative;
  object-fit: contain;
}
.triumphCo {
  align-self: stretch;
  flex: 2;
  position: relative;
  font-size: var(--body1-regular-size);
  font-family: var(--body1-regular);
  color: var(--color);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aboutUsParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-151xl) 0rem 0rem;
  gap: var(--gap-xl);
}
.pagehaeder {
  align-self: stretch;
  background-color: var(--color-gray-200);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-31xl);
  text-align: center;
  font-size: var(--display2-size);
  color: var(--color1);
  font-family: var(--display2);
  padding-bottom: 50px;
}


@media screen and (max-width: 500px) {
  .aboutUsParent{
    flex-direction: column;
    padding: 10px;
  }
  .aboutUs{
    border-bottom: solid;
    border-right: none;
    max-width: none;
  }
  .pagehaeder {
    gap: 0px;
  }
  
}
.maskGroupIcon {
  align-self: flex-start;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.education {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
  display: flex;
  align-items: center;
}
.educationdetiels {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
  font-size: var(--font-size-13xl);
}
.maskGroupParent {
  height: 45.333rem;
  display: flex;
  flex:1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color2);
  font-family: var(--body1-regular);
}

.maskGroupIcon {
  height: 26.556rem;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
}
.componentChild {
  height: 26.556rem;
  width: 0.111rem;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
.aboutUs {
  align-self: stretch;
  height: 1.722rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.supportingYouTo {
  letter-spacing: -0.03em;
}
.overcomeLegal {
  letter-spacing: 0.03em;
}
.issuesAndEase {
  letter-spacing: 0.05em;
}
.supportingYouToContainer {
  margin: 0;
  align-self: stretch;
  flex: 1;
  position: relative;
  font-size: var(--font-size-45xl);
  text-transform: capitalize;
  font-weight: 600;
  font-family: inherit;
  color: var(--color-gray-200);
}
.aboutSupport {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
}
.maskGroupParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--color-gray-100);
  font-family: var(--body1-regular);
}

.frameDivePadding{
  padding: 0rem var(--padding-131xl);
}

@media screen and (max-width: 500px) {
  .frameDivePadding{
    padding: 0rem 2rem;
  }
}
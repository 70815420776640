.globalPresence {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 96%;
  text-transform: capitalize;
  font-weight: 800;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.image106Icon {
  align-self: stretch;
  height: 43.778rem;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.globalpresence {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem var(--padding-181xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
  max-height: 50rem;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-77xl);
  color: var(--color3);
  font-family: var(--body1-regular);
}
.homepage {
  width: 100%;
  position: relative;
  background-color: var(--color);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-81xl);
  line-height: normal;
  letter-spacing: normal;
}

@media screen and (max-width: 1425px) {
  .globalpresence {
    padding-left: var(--padding-81xl);
    padding-right: var(--padding-81xl);
    box-sizing: border-box;
  }

  .homepage {
    gap: var(--gap-31xl);
  }
}
@media screen and (max-width: 950px) {
  .globalPresence {
    font-size: var(--font-size-29xl);
    line-height: 3.056rem;
  }

  .globalpresence {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }

  .homepage {
    gap: var(--gap-6xl);
  }
}
@media screen and (max-width: 450px) {
  .globalPresence {
    font-size: var(--font-size-10xl);
    line-height: 2.056rem;
  }
}

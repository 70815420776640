.maskGroupIcon {
  align-self: stretch;
  height: 25.944rem;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.ourPracticeAreas {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 130%;
  text-transform: capitalize;
  font-weight: 400;
  font-family: inherit;
}
.practiceAreas {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  max-width: 100%;
}
.areasWeServe {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 130%;
  text-transform: capitalize;
  font-weight: 600;
  font-family: inherit;
}
.triumphCo {
  margin: 0;
  position: relative;
  font-size: var(--font-size-13xl);
  text-transform: capitalize;
  font-weight: 400;
  font-family: inherit;
}
.areasWeServeParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem 0rem var(--padding-147xl);
  box-sizing: border-box;
  gap: var(--gap-31xl);
  min-width: 33.833rem;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-61xl);
}
.areasContainer {
  width: 90rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-31xl);
  max-width: 100%;
}
.serviceItems {
  width: 90rem;
  height: 3.95rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  font-size: var(--font-size-21xl);
}
.serviceItems1 {
  width: 90rem;
  height: 3.95rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 0rem 0rem;
  box-sizing: border-box;
  gap: var(--gap-0);
  row-gap: 20px;
  max-width: 100%;
}
.services {
  align-self: stretch;
  background-color: var(--color-gray-200);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-31xl) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-31xl);
  color: var(--color1);
  font-family: var(--body1-regular);
}

@media screen and (max-width: 1425px) {
  .practiceAreas {
    flex: 1;
  }

  .areasContainer {
    flex-wrap: wrap;
  }

  .services {
    padding-top: var(--padding-13xl);
    padding-bottom: var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 950px) {
  .ourPracticeAreas {
    font-size: var(--font-size-21xl);
    line-height: 2.889rem;
  }

  .practiceAreas {
    gap: var(--gap-xl);
    min-width: 100%;
  }

  .areasWeServe {
    font-size: var(--font-size-21xl);
    line-height: 3.444rem;
  }

  .triumphCo {
    font-size: var(--font-size-7xl);
  }

  .areasWeServeParent {
    gap: var(--gap-6xl);
    padding-bottom: var(--padding-89xl);
    box-sizing: border-box;
    min-width: 100%;
  }

  .areasContainer {
    gap: var(--gap-6xl);
  }

  .services {
    padding-top: var(--padding-2xl);
    padding-bottom: var(--padding-2xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .ourPracticeAreas {
    font-size: var(--font-size-11xl);
    line-height: 2.167rem;
  }

  .areasWeServe {
    font-size: var(--font-size-5xl);
    line-height: 2.333rem;
  }

  .triumphCo {
    font-size: var(--font-size-lgi);
  }
}

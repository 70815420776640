.whyChoose {
  margin: 0;
}
.whyChooseTriumphContainer {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  text-transform: capitalize;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
}
.whyChooseUsTitle {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem var(--padding-144xl) 0rem;
  box-sizing: border-box;
}
.chooseTriumphContainer {
  margin: 0;
  position: relative;
  font-size: var(--font-size-21xl);
  font-weight: 400;
  font-family: inherit;
  color: var(--color2);
}
.whyChooseUsContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: var(--padding-xl) 0rem;
  box-sizing: border-box;
  gap: var(--gap-7xl);
  max-width: 100%;
}
.whyChooseUsContainerChild {
  position: relative;
  max-height: 20rem;
}
.image120Icon {
  height: 34.333rem;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
}
.imageContainer {
  display: flex;
  flex:1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-31xl);
  box-sizing: border-box;
  max-width: 100%;
}
.whyChooseUsContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  row-gap: 20px;
  max-width: 100%;
  gap: var(--gap-0);
  padding: 0px var(--padding-62xl);
}
.seeDetailButtonContainer {
  width: 13.889rem;
  cursor: pointer;
}
.whychoseus {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-52xl);
  max-width: 100%;
  text-align: center;
  font-size: var(--h1-size);
  color: var(--color-gray-200);
  font-family: var(--body1-regular);
}

@media screen and (max-width: 1890px) {
  .whyChooseUsContainer {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 1425px) {
  .whyChooseUsTitle {
    padding-left: var(--padding-62xl);
    padding-right: var(--padding-62xl);
    box-sizing: border-box;
  }

  .imageContainer {
    padding-left: var(--padding-6xl);
    padding-right: var(--padding-6xl);
    box-sizing: border-box;
  }

  .whyChooseUsContainer {
    flex-wrap: wrap;
    justify-content: center;
  }

  .whychoseus {
    gap: var(--gap-16xl);
  }
}
@media screen and (max-width: 950px) {
  .whyChooseTriumphContainer {
    font-size: var(--font-size-39xl);
  }

  .whyChooseUsTitle {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }

  .chooseTriumphContainer {
    font-size: var(--font-size-13xl);
  }

  .imageContainer {
    padding-top: var(--padding-13xl);
    padding-bottom: var(--padding-13xl);
    box-sizing: border-box;
  }

  .whyChooseUsContainer {
    flex-wrap: wrap;
    justify-content: center;
  }

  .whychoseus {
    gap: var(--gap-lg);
  }
}
@media screen and (max-width: 450px) {
  .whyChooseTriumphContainer {
    font-size: var(--font-size-24xl);
  }

  .chooseTriumphContainer {
    font-size: var(--font-size-5xl);
  }

  .whyChooseUsContainer {
    flex-wrap: wrap;
    justify-content: center;
  }

  .seeDetailButtonContainer {
    width: calc(100% - 40px);
  }
}

.getInTouch {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  text-transform: capitalize;
  font-weight: 400;
  font-family: inherit;
}
.nameFields {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-31xl);
}
.optional {
  font-size: var(--body1-regular-size);
  font-family: var(--body1-regular);
}
.messageOptional {
  position: relative;
}
.messageInputContainerChild {
  border: 1.5px solid var(--color4);
  background-color: transparent;
  height: 13.889rem;
  width: auto;
  outline: none;
  align-self: stretch;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-11xl);
  font-family: var(--body1-regular);
  font-size: var(--body1-regular-size);
  color: var(--color-gray-300);
}
.messageInputContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  max-width: 100%;
}
.messageField {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.inputFields {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-31xl);
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-13xl);
}
.button {
  width: 13.889rem;
}
.formFields {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-61xl);
  min-width: 24.278rem;
  max-width: 100%;
}
.contactImageIcon {
  height: 52.778rem;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
}
.imagePlaceholderIcon {
  height: 3rem;
  width: 14.722rem;
  position: absolute;
  margin: 0 !important;
  top: 17.056rem;
  left: 1.667rem;
  max-width: 100%;
  overflow: hidden;
  z-index: 1;
}
.contactImageParent {
  flex: 0.9569;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem var(--padding-10xl) 0rem 0rem;
  box-sizing: border-box;
  position: relative;
  min-width: 24.278rem;
  max-width: 100%;
}
.formContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-48xl) 0rem;
  box-sizing: border-box;
  gap: var(--gap-31xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--h1-size);
  color: var(--color-gray-300);
  font-family: var(--display2);
}

@media screen and (max-width: 1425px) {
  .formFields {
    flex: 1;
  }

  .contactImageParent {
    flex: 1;
  }

  .formContainer {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1200px) {
  .formContainer {
    padding-top: var(--padding-25xl);
    padding-bottom: var(--padding-25xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 825px) {
  .getInTouch {
    font-size: var(--font-size-39xl);
  }

  .nameFields {
    gap: var(--gap-6xl);
    flex-wrap: wrap;
  }

  .messageField {
    gap: var(--gap-31xl);
  }

  .inputFields {
    gap: var(--gap-6xl);
  }

  .formFields {
    gap: var(--gap-21xl);
    min-width: 100%;
  }

  .contactImageParent {
    min-width: 100%;
  }

  .formContainer {
    gap: var(--gap-6xl);
    padding-top: var(--padding-10xl);
    padding-bottom: var(--padding-10xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .getInTouch {
    font-size: var(--font-size-24xl);
  }

  .messageField {
    gap: var(--gap-6xl);
  }

  .formFields {
    gap: var(--gap-xl);
  }
}

.teamcardParent {
  align-self: stretch;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  padding: 4.944rem 8.222rem;
  gap: 5.555rem 5.333rem;
}
.lawyer {
  width: 100%;
  position: relative;
  background-color: var(--color);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  line-height: normal;
  letter-spacing: normal;
}

@media screen and (max-width: 850px) {
  .teamcardParent {
    gap: 2.667rem;
    padding: 3.222rem 4.111rem;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .teamcardParent {
    gap: 1.333rem;
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}

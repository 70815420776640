.maskGroupIcon {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
  min-width: 22.222rem;
}
.mariamAlShamsi {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
}
.partner {
  position: relative;
  font-size: var(--font-size-5xl);
  font-family: var(--display2);
  text-align: left;
}
.teamCardInfo {
  width: 22.222rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.teamcard {
  height: 29.444rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-17xl);
  color: var(--color2);
  font-family: var(--body1-regular);
}

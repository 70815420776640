.formContainerWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem var(--padding-131xl);
  box-sizing: border-box;
  max-width: 100%;
}
.contactUs {
  width: 100%;
  position: relative;
  background-color: var(--color);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-81xl);
  line-height: normal;
  letter-spacing: normal;
}

@media screen and (max-width: 1200px) {
  .formContainerWrapper {
    padding-left: var(--padding-56xl);
    padding-right: var(--padding-56xl);
    box-sizing: border-box;
  }

  .contactUs {
    gap: var(--gap-31xl);
  }
}
@media screen and (max-width: 450px) {
  .formContainerWrapper {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }

  .contactUs {
    gap: var(--gap-6xl);
  }
}

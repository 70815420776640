.maskGroupIcon {
  width: 41.667rem;
  height: 24.222rem;
  position: relative;
  object-fit: contain;
}
.theCaseOf {
  margin: 0;
  width: 39.556rem;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  white-space: pre-wrap;
  display: inline-block;
}
.loremIpsumDolor {
  width: 39.056rem;
  position: relative;
  font-size: var(--body1-regular-size);
  display: inline-block;
}
.caseDetails {
  width: 13.889rem;
}
.caseContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-11xl);
}
.case {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-11xl);
  text-align: left;
  font-size: var(--font-size-25xl);
  color: var(--color2);
  font-family: var(--body1-regular);
}

@media screen and (max-width: 950px) {
  .case {
    min-width: 100%;
  }
}

.root {
  margin: 0;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--body1-regular-size);
  color: var(--color2);
  font-family: var(--body1-regular);
}
.root[data-theme="black"] {
  margin: unset;
}

.mobileHide{
  display: flex;
}

.mobileShow{
  display: none;
}



@media screen and (max-width: 500px){
  .mobileHide{
    display: none;
  }
  
  .mobileShow{
    display: flex;
  }

  .root{
    flex-direction: column;
    font-size: var(--font-size-13xl);
  }
}
.weHaveHandled {
  margin: 0;
  width: 57.778rem;
  position: relative;
  font-size: inherit;
  text-transform: capitalize;
  font-weight: 600;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 57.778rem;
  box-sizing: border-box;
  padding-left: var(--padding-xl);
  padding-right: var(--padding-xl);
}
.seeMore1 {
  flex: 1;
  position: relative;
  display: inline-block;
  min-width: 3.611rem;
}
.arrowRight3 {
  height: 1.778rem;
  width: 1.778rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.seeMore {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-end;
  padding: 0rem 0rem 0rem var(--padding-1749xl);
  gap: var(--gap-xl);
  font-size: var(--font-size-5xl);
  font-family: var(--display2);
}
.casesHeader {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
  max-width: 100%;
}
.caseList {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  max-width: 100%;
  gap: var(--gap-0);
  row-gap: 20px;
  text-align: left;
  font-size: var(--font-size-25xl);
}
.lastcases {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  text-align: center;
  font-size: var(--h1-size);
  color: var(--color2);
  font-family: var(--body1-regular);
}

@media screen and (max-width: 1425px) {
  .weHaveHandled {
    max-width: 100%;
  }

  .seeMore {
    padding-left: var(--padding-865xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 950px) {
  .weHaveHandled {
    font-size: var(--font-size-39xl);
  }

  .seeMore {
    padding-left: var(--padding-423xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .weHaveHandled {
    font-size: var(--font-size-24xl);
  }

  .seeMore1 {
    font-size: var(--font-size-lgi);
  }
}

.aboutTriumph {
  margin: 0;
  font-weight: 600;
}
.triumphCoIsAnInternatio1 {
  font-weight: 400;
}
.triumphCoIsAnInternatio {
  margin: 0;
  font-size: var(--font-size-21xl);
}
.blankLine {
  margin: 0;
  font-size: var(--font-size-26xl);
}
.ourTeamConsists {
  margin: 0;
  font-size: var(--font-size-21xl);
  font-weight: 400;
}
.aboutTriumphContainer {
  align-self: stretch;
  position: relative;
}
.missionStatementAtContainer {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  gap: 3rem;
}
.aboutTriumphCoTriumphParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0rem 9.277rem;
  gap: var(--gap-61xl);
  text-align: left;
  font-size: var(--font-size-41xl);
  color: var(--color2);
  font-family: var(--body1-regular);
}
.aboutUs {
  width: 100%;
  position: relative;
  background-color: var(--color);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-81xl);
  line-height: normal;
  letter-spacing: normal;
}

@media screen and (max-width: 975px) {
  .aboutTriumphCoTriumphParent {
    gap: 2rem;
    padding-left: var(--padding-64xl);
    padding-right: var(--padding-64xl);
    box-sizing: border-box;
  }

  .aboutUs {
    gap: var(--gap-31xl);
  }
}
@media screen and (max-width: 700px) {
  .aboutTriumphCoTriumphParent {
    gap: var(--gap-lg);
    padding-left: var(--padding-22xl);
    padding-right: var(--padding-22xl);
    box-sizing: border-box;
  }

  .aboutUs {
    gap: var(--gap-6xl);
  }
}


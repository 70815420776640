.text {
  text-decoration: none;
  position: relative;
  color: inherit;
  display: inline-block;
}
.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--body1-regular-size);
  color: var(--color2);
  font-family: var(--body1-regular);
}
.root[data-color="Dark"][data-state="Default"] .text {
  height: unset;
  color: var(--color1);
  display: unset;
}
.root[data-color="Dark"][data-state="clicked"] .text {
  height: unset;
  color: var(--color1);
  display: unset;
  font-weight: 700;
}
.root[data-color="Default"][data-state="clicked"] .text {
  font-weight: 700;
}
.root :hover {
  font-weight: 700 ;
  background: transparent;
}


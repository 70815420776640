.bachelorOfLaw {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
  display: flex;
  align-items: center;
}
.americanUniversityIn {
  margin: 0;
  position: relative;
  font-size: var(--font-size-6xl);
  font-weight: 400;
  font-family: inherit;
  display: flex;
  align-items: center;
}
.educationdetiale {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color2);
  font-family: var(--body1-regular);
}

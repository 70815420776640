.lawyerDetailMaryiam {
  width: 100%;
  position: relative;
  background-color: var(--color);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-81xl);
  line-height: normal;
  letter-spacing: normal;
}

@media screen and (max-width: 848px) {
  .lawyerDetailMaryiam {
    gap: var(--gap-31xl);
  }
}
@media screen and (max-width: 424px) {
  .lawyerDetailMaryiam {
    gap: var(--gap-6xl);
  }
}

.tLogo1 {
  width: 11.111rem;
  height: 11.111rem;
  position: relative;
  object-fit: cover;
  
}
.logo {
  flex:1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 500px) {
  .tLogo1 {
    width: 7rem;
    height: 7rem;
  }
}

.mariamAlShamsi {
  margin: 0;
  position: relative;
  font-size: inherit;
  text-transform: capitalize;
  font-weight: 600;
  font-family: inherit;
  display: flex;
  align-items: center;
}
.partner {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-26xl);
  text-transform: capitalize;
  font-weight: 400;
  font-family: inherit;
}
.experienceDetails {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.experience {
  margin: 0;
  position: relative;
  font-size: inherit;
  text-transform: capitalize;
  font-weight: 600;
  font-family: inherit;
}
.mariamIsLicensed {
  margin: 0;
}
.mariamIsLicensedContainer {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-13xl);
  font-weight: 400;
  font-family: inherit;
}
.experienceDetails1 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-21xl);
}
.experienceDetailsParent {
  align-self: stretch;
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-81xl) 0rem 0rem;
  gap: var(--gap-11xl);
  font-size: var(--h1-size);
}
.lawerdetaies {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem var(--padding-131xl);
  box-sizing: border-box;
  gap: var(--gap-81xl);
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color2);
  font-family: var(--body1-regular);
}

@media screen and (max-width: 800px) {
  .lawerdetaies {
    padding: 20px;
    gap: 10px;
  }
  .experienceDetailsParent {
    padding: 0px;
  }
}

@media screen and (max-width: 500px) {
  .lawerdetaies {
    flex-direction: column-reverse;
  }
}